@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @responsive {
        .show{
            opacity: 1;
            filter: blur(0);
            transform: translateX(30%);
            transition: all 2s;
        };
        .hide{
            opacity: 0;
            filter: blur(5px);
            transform: translateX(-100%);
            transition: all 2s;
        }
    }
  }